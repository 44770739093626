<template>
  <div class="bg-[#262653] text-white px-3 overflow-y-scroll h-[80dvh]">
    <ul class="mt-8">
    

      <li class="flex items-center ml-3 mb-2 space-x-3">
        <router-link
          to="/"
          class="text-sm p-4 leading-none rounded-full hover:bg-[#262653]"
          >{{ $t('Home') }}</router-link
        >
      </li>
      <li class="flex items-center ml-3 mb-2 space-x-3">
        <router-link
        to="/leaderboard"
          class="text-sm p-4 leading-none rounded-full hover:bg-[#262653]"
        >
          {{ $t('Leaderboard') }}
        </router-link>
      </li>
      <li 
      v-if="!isLoggedIn"
      class="flex items-center ml-3 mb-2 space-x-3">
        <a
          href="#"
          @click="openLogin"
          class="text-sm p-4 leading-none rounded-full hover:bg-[#262653]"
          >{{ $t('Subscribe/Login') }}</a
        >
      </li>

      <li 
      v-if="isLoggedIn"
      class="flex items-center ml-3 mb-2 space-x-3">
        <router-link
          to="/user/profile"
          class="text-sm p-4 leading-none rounded-full hover:bg-[#262653]"
          >{{ $t('Profile') }}</router-link
        >
      </li>

      <li v-if="isLoggedIn" class="flex items-center ml-3 mb-2 space-x-3">
        <router-link
          v-if="userInfo && userInfo.is_subscribed"
          to="/unsubscribe"
          class="text-sm p-4 leading-none rounded-full hover:bg-[#262653]"
          >{{ $t('Unsubscribe') }}</router-link
        >
        <a
        v-else
          @click="openSubscription"
          href="#"
          class="text-sm p-4 leading-none rounded-full hover:bg-[#262653]"
          >{{ $t("Subscribe") }}</a
        >
      </li>

      <li
      v-if="isLoggedIn"
      class="flex items-center ml-3 mb-2 space-x-3">
        <button
          class="text-sm p-4 leading-none rounded-full hover:bg-[#262653]"
          @click="onLogout"
        >
          {{ $t('Logout') }}
        </button>
      </li>
      <button
        @click="setLanguage"
        class="inline-flex items-center justify-center gap-2text-sm p-4 leading-none rounded-full hover:bg-[#262653]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"
          />
        </svg>

        {{ $t("language") }}
      </button>
    </ul>
  </div>

  <!-- Language Dialog -->
  <div v-if="showDialog" class="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded shadow-lg w-64">
      <h3 class="text-xl mb-4 text-black">{{ $t("Select Language") }}</h3>
      <ul>
        <li
          v-for="(lang, index) in languages"
            :key="index"
            @click="changeLanguage(lang.code)"
            :class="{
              'bg-blue-500 text-white': currentLanguage === lang.code,
              'hover:bg-gray-200 text-black': currentLanguage !== lang.code
            }"
            class="cursor-pointer p-2 rounded mb-2 "
        >
          {{ lang.name }}
        </li>
      </ul>
      <button @click="showDialog = false" class="mt-4 w-full bg-gray-500 text-white py-2 rounded">{{ $t("Close") }}</button>
    </div>
  </div>

</template>

<script>
import { useAuthStore } from "@/store/auth";
import helpers from "@/helpers";
import { mapActions, mapState } from "pinia";
import { useLanguageStore } from "@/store/language";
import { LOCAL } from "@/constants";

export default {
  data () {
    return {
      items: ['Top players', 'Arcade', 'Blog', 'Sample page', 'Login'],
      showDialog: false,
      languages: [],
      currentLanguage: null,
    }
  },
  computed: {
    ...mapState(useAuthStore, ["isLoggedIn", "userInfo"]),
  },
  methods: {
    ...mapActions(useLanguageStore, ["setLocale"]),
    ...mapActions(useAuthStore, ["logout", "unsubscribe", "openPaymentPage"]),
    openSubscription() {
      localStorage.setItem('redirectUrl', this.$route.fullPath);

      let config = helpers.getDomainConfig();
      if(config.subscriptionType == "card"){
        if(!this.isLoggedIn){
          this.openLogin();
        } else {
          this.openPaymentPage()
        }
      } else {
        window.location.href = config.subscribeUrl;
      }
    },
    openLogin() {
      localStorage.setItem('redirectUrl', this.$route.fullPath);
      this.$router.push({ name: "user.login" });
    },
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.setLocale(language);
      this.currentLanguage = language;

      // Handle the language change here, for example by updating a Vuex state or localStorage
      this.showDialog = false; // Close the dialog
    },
    setLanguage() {

      if(this.languages.length > 2){
        this.showDialog = true;
        return;
      }

      const firstLang = helpers.getDomainConfig().firstLang;
      const secondLang = helpers.getDomainConfig().secondLang;
      let locale = localStorage.getItem(LOCAL) == secondLang ? firstLang : secondLang;
      this.$i18n.locale = locale;
      this.currentLanguage = locale;
      this.setLocale(locale);
    },
    async onLogout() {
      await this.logout();
      // Go to home or redirect the home if the user is on the home page
      this.$router.push("/");
    },
  },
  mounted() {
    const supportedLanguages = helpers.getDomainConfig().supportedLanguages;

    this.languages = supportedLanguages.map(code => ({
      code,
      name: this.$t(`languages.${code}`),
    }));
  },
}
</script>

<style></style>
