// src/store/category.js

import { defineStore, acceptHMRUpdate } from 'pinia';
import { AUTHTOKEN } from '@/constants';
import helpers from "../helpers";
import { http } from "@/store/https";
import { loadStripe } from '@stripe/stripe-js';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        isLoggedIn: localStorage.getItem(AUTHTOKEN) ? true : false,
        token: localStorage.getItem(AUTHTOKEN) ? localStorage.getItem(AUTHTOKEN) : false,
        userInfo: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
        lang: localStorage.getItem('selectedLocale'),
        error: null
    }),
    getters: {
        getIsloggedIn: (state) => state.isLoggedIn,
        getUserData: (state) => state.userInfo,
        getError: (state) => state.error,
        getToken: (state) => state.token,

    },
    actions: {
        /**
         * subscribe action
         * @param phone
         * @response josn
         */
        subscribe(phone) {
            const domainsConfig = helpers.getDomainConfig();
            const productId = domainsConfig.productId;
            const apiKey = domainsConfig.apiKey;
            return new Promise((resolve, reject) => {
                http.post('/auth/subscribe/'+apiKey, { 
                    phone: phone,
                    product_id: productId
                })
                .then((response) => {
                    if (response.data.access_token) {
                        this.token = response.data.access_token
                        this.isLoggedIn = true
                        this.userInfo = response.data.client
                        localStorage.setItem("user", JSON.stringify(response.data.client))
                        localStorage.setItem(AUTHTOKEN, response.data.access_token)
                        // Set the phone number and the product id in the local storage
                        localStorage.setItem('phone', phone)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },

        autoLogin(phone) {
            const productId = helpers.getDomainConfig().productId;
            return new Promise((resolve) => {
                http.post('/auth/auto-login/', { 
                    phone: phone,
                    product_id: productId
                })
                .then((response) => {
                    if (response.data.access_token) {
                        this.token = response.data.access_token
                        this.isLoggedIn = true
                        this.userInfo = response.data.client
                        localStorage.setItem("user", JSON.stringify(response.data.client))
                        localStorage.setItem(AUTHTOKEN, response.data.access_token)
                    } else {
                        this.clearData();
                    }
                    resolve(response)
                })
                .catch((error) => {
                    this.clearData();
                    console.error(error)
                });
            });
        },
        login(userLogin) {
            const productId = helpers.getDomainConfig().productId;
            return new Promise((resolve) => {
                http.post('/auth/login/', { 
                    user_login: userLogin,
                    product_id: productId
                })
                .then((response) => {
                    if (response.data.access_token) {
                        this.token = response.data.access_token
                        this.isLoggedIn = true
                        this.userInfo = response.data.client
                        localStorage.setItem("user", JSON.stringify(response.data.client))
                        localStorage.setItem(AUTHTOKEN, response.data.access_token)

                        localStorage.setItem('phone', response.data.client.phone)

                        resolve({
                            "success": true,
                            "message": "Login Completed successfully"
                        })
                    } else {
                        resolve({
                            "success": false,
                            "message": "Do not have an account"
                        })
                    }
                })
                .catch(() => {
                    resolve({
                        "success": false,
                        "message": "Do not have an account"
                    })
                });
            });
        },

        register(name, phone, email) {
            const productId = helpers.getDomainConfig().productId;
            return new Promise((resolve) => {
                http.post('/auth/register/', { 
                    name: name,
                    phone: phone,
                    email: email,
                    product_id: productId
                })
                .then((response) => {
                    if (response.data.access_token) {
                        this.token = response.data.access_token
                        this.isLoggedIn = true
                        this.userInfo = response.data.client
                        localStorage.setItem("user", JSON.stringify(response.data.client))
                        localStorage.setItem(AUTHTOKEN, response.data.access_token)

                        localStorage.setItem('phone', response.data.client.phone)

                        resolve({
                            "success": true,
                            "message": "Account created successfully"
                        })
                    } else {
                        resolve({
                            "success": false,
                            "message": "Do not have an account"
                        })
                    }
                })
                .catch(() => {
                    resolve({
                        "success": false,
                        "message": "Failed to register"
                    })
                });
            });
        },

        /**
         * logout action
         */
        async logout() {
            return new Promise((resolve) => {
                http.post('/auth/logout')
                    .then((response) => {
                        this.isLoggedIn = false
                        localStorage.removeItem("phone")
                        this.clearData();
                        resolve(response)
                    })
                    .catch(() => {
                        this.clearData();
                        resolve();
                    });
            });
        },
        /**
         * unsubscribe action 
         * @response json
         */
        async unsubscribe() {
            return new Promise((resolve) => {
                http.post('/auth/unsubscribe')
                    .then((response) => {
                        this.userInfo = response.data.client
                        localStorage.setItem("user", JSON.stringify(response.data.client))
                        resolve({
                            "success": true,
                        });
                    })
                    .catch(() => {
                        resolve({
                            "success": false,
                        });
                    });
            });
        },

        /**
         * update-last-play action 
         * @response json
         */
        async updateLastPlay() {
            return new Promise((resolve, reject) => {
                // if user not logged in, return
                if (!this.isLoggedIn) {
                    resolve()
                    return
                }
                http.post('/auth/update-last-play', {}, { showLoading: false })
                    .then((response) => {
                        this.userInfo = response.data.client
                        localStorage.setItem("user", JSON.stringify(response.data.client))
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.response.data);
                    });
            });
        },

        /**
         * clear local storage when user is logged out or unsubscribed 
         */
        clearData() {
            this.isLoggedIn = false
            this.token = false
            this.userInfo = {}
            localStorage.removeItem(AUTHTOKEN)
            localStorage.removeItem("user")
        },


        /**
         * Update profile
         */
        async updateProfile(name, birthDate, email) {
            return new Promise((resolve, reject) => {
                http.post('/auth/update-profile', {
                    name: name,
                    birth_date: birthDate,
                    email: email,
                })
                .then((response) => {
                    this.userInfo = response.data.client
                    localStorage.setItem("user", JSON.stringify(response.data.client))
                    resolve(response)
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },

        verifyPayment(sessionId) {
            return new Promise((resolve, reject) => {
                http.post('/stripe/verify-payment/', { 
                    session_id: sessionId,
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
        async openPaymentPage() {
            const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
            const productId = helpers.getDomainConfig().productId;

            try {
              const response = await http.post("/stripe/create-checkout-session",{
                product_id: productId,
              }, { headers: { "Content-Type": "application/json" } });
          
              await stripe.redirectToCheckout({
                sessionId: response.data.data.session_id,
              });
            } catch (error) {
              console.error("Error creating checkout session:", error);
            }          
        }
    }

})


// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(useAuthStore, import.meta.webpackHot))
}