<template>
  <div class="md:mx-24">
    <footer class="z-40 w-full justify-center">
      <div class="container mx-auto">
        <ul
          class="flex flex-wrap justify-center space-x-4 text-gray-200 items-center"
        >
          <li class="text-center" style="margin: 0 0.5rem">
            <router-link to="/about" class="md:text-md text-[12px]">{{
              $t("About Mars")
            }}</router-link>
          </li>
          <span class="text-gray-200 mx-2">|</span>
          <li class="text-center">
            <router-link to="/terms" class="md:text-md text-[12px]">{{
              $t("Terms")
            }}</router-link>
          </li>
          <span class="text-gray-200 mx-2">|</span>
          <li class="text-center">
            <router-link to="/policy" class="md:text-md text-[12px]">{{
              $t("Policy & Privacy")
            }}</router-link>
          </li>
          <span class="text-gray-200 mx-2">|</span>
          <li class="text-center">
            <router-link to="/contacts" class="md:text-md text-[12px]">{{
              $t("Contact Us")
            }}</router-link>
          </li>
        </ul>
      </div>

      <div class="flex flex-col text-center h-16 w-full">
        <p class="text-xs text-gray-400 mt-8">
          © {{ new Date().getFullYear() }}
          {{ $t("Enthalpy Technologies All Rights Reserved") }}.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
